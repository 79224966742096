import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
// stylesheet
import './styles.scss';
// svg icons
import emailIcon from '../../assets/material-email-icon.svg';
import whatsappIcon from '../../assets/ionic-whatsapp-icon.svg';
// images
import logo from '../../assets/business-icon@2x.png';
function Header() {
  const toggleNavbar = (e) => {
    $(".menu-btn").toggleClass('active')
    $(".navbar").slideToggle('fast')
  }
  return (
    <div className="header">
      <div className="wrapper">
        <Link to="/">
          <div className="logo">
            <div className="company-img">
              <img src={logo} title="Art's Photocopy Company" alt="Art's Photocopy Company" />
            </div>
            <div className="company-name" title="Art's Photocopy Company">
              <h2>新藝彩色影印</h2>
              <h2>ART’S PHOTOCOPY COMPANY</h2>
            </div>
          </div>
        </Link>
        <div className="contact-info">
          <ul>
            <li>
              <a href="mailto:artscopy01@gmail.com">
                <img src={emailIcon} alt="Email" />
                <span>artscopy01@gmail.com</span>
              </a>
            </li>
            <li>
            <a href="https://wa.me/85296693920" target="_blank"  rel="noreferrer">
              <img src={whatsappIcon} alt="Whatsapp" />
              <span>+852 9669 3920</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="menu-btn" onClick={toggleNavbar}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  )
};

export default React.memo(Header)