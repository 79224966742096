import React from 'react';

import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
export default function MainLayout({ children }) {

  return (
    <div className="main-layout">
      <Header />
      <Navbar />
      <div className="main">
        {children}
      </div>
      <Footer />
    </div>
  )
};
