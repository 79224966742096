import React, { useCallback } from 'react'
import $ from 'jquery'
import './styles.scss'
import useCheckResize from '../../customHooks/useCheckResize'

const showDetail = (e) => {
  if (window.innerWidth <= 760) {
    const ele = $(e.target)
    $('.footer .contact-item .title').each(function () {
      if (ele.text() !== this.innerText) {
        $(this).next().hide()
      }
    })
    ele.next().slideToggle('fast')
  }
}

function Footer() {
  useCheckResize(useCallback(() => {
    if (window.innerWidth > 760) {
      $('.detail-wrapper').show()
    } else {
      $('.detail-wrapper').hide()
    }
  }, []))

  return (
    <footer id="footer" className="footer">
      <div className="wrapper">
        <div className="contact-items">
          <div className="contact-item">
            <h3 className="title" onClick={showDetail}>公司地址</h3>
            <div className="detail-wrapper">
              <div className="detail">
                <h3>香港灣仔</h3>
                <h3>軒尼詩道344A – 348A號</h3>
                <h3>昌業大廈閣樓F室</h3>
              </div>
            </div>
          </div>
          <div className="contact-item">
            <h3 className="title" onClick={showDetail}>營業時間</h3>
            <div className="detail-wrapper">
              <div className="detail">
                <h3>星期一至五 上午11時至下午6時</h3>
                <h3>星期六 上午11時至下午6時</h3>
                <h3>星期日及公眾假期休息</h3>
              </div>
            </div>
          </div>
          <div className="contact-item">
            <h3 className="title" onClick={showDetail}>聯絡方法</h3>
            <div className="detail-wrapper">
              <div className="detail">
                <h3>電郵: artscopy01@gmail.com</h3>
                <h3>電話: +852 28932176</h3>
                <h3>Whatsapp: +852 96693920</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="other-info">
          <h3 className="company-info">
            <span><b>新藝彩色影印</b>在<b>印刷業</b>中有<b>超過二十多年經驗</b>，提供<b>優質及專業的影印服務</b>。</span>
            <span>本公司亦致力與每個客戶<b>建立良好及鞏固的關係</b>，了解其需要。</span>
          </h3>
          <h5 className="copyright">
            © ARTS PHOTOCOPY COMPANY 2020. All Rights Reserved
          </h5>
          <h5 className="credits">
            Icons made by <a target="_blank" rel="noreferrer" href="https://www.flaticon.com/authors/becris"
              title="Becris">Becris</a>, <a target="_blank" rel="noreferrer" href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a>,
              <a target="_blank" rel="noreferrer" href="https://www.flaticon.com/authors/freepik" title="Freepik"> Freepik</a>,
              <a target="_blank" rel="noreferrer" href="https://www.flaticon.com/authors/flat-icons" title="Flat Icons"> Flat Icons</a> from
            <a target="_blank" rel="noreferrer" href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
          </h5>
        </div>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
