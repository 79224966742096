import { useEffect } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
function useCheckResize(callback) {
  useEffect(() => {
    callback()
    $(window).on('resize', callback)
    return () => {
      $(window).off('resize', callback)
    }
  }, [callback])
}

export default useCheckResize
useCheckResize.propTypes = {
  callback: PropTypes.func
}
