import React, { useEffect, Suspense, lazy } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
// import logo from './logo.svg';
import './App.scss';
// Layouts
import MainLayout from './layouts/MainLayout';
// Components
import Loader from './components/Loader';
// Pages
const Homepage = lazy(() => import('./pages/Homepage'));
const Contact = lazy(() => import('./pages/Contact'));

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location]);
}

function App() {
  usePageViews()

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/" exact render={() => (
            <MainLayout>
              <Homepage />
            </MainLayout>
          )} />
          <Route path="/contact" render={() => (
            <MainLayout>
              <Contact />
            </MainLayout>
          )} />
        </Switch>
      </Suspense>
    </div>

  );
};

export default App;
