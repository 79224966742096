import React from 'react'
import './styles.scss'
export default function Loader() {
  return (
    <div className="loader">
      <span className="loader-wrapper" >
        Loading...
      </span>
    </div>
  )
}
