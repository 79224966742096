import React, { useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import './styles.scss';

import useCheckResize from '../../customHooks/useCheckResize';
export default function Navbar() {
  const history = useHistory()

  useCheckResize(useCallback(() => {
    if (window.innerWidth > '610') {
      $('.navbar').show().removeClass('navbar-sd')
    } else {
      $('.navbar').hide().addClass('navbar-sd')
      $('.header .menu-btn').removeClass('active')
    }
  }, []))

  const handleClick = (e) => {
    e.preventDefault();
    const ele = $(e.target);
    if (ele.is("a")) {
      // this line is duplicated (refer to useEffect)
      ele.parent().addClass("active").siblings().removeClass("active");
      $(".menu-btn").removeClass('active')
      $(".navbar-sd").slideToggle("fast")
      // handle hash links 
      if (ele.attr('title') === "服務範圍") {
        history.push('/')
        // when the document is loaded, scroll into the target element
        $(() => {
          const target1 = document.getElementById("service-scope")
          target1.scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" })
        }
        )
      }
    }
  };
  useEffect(() => {
    // activate the current url
    const navItems = $('.navbar .wrapper ul li')
    navItems.removeClass('active')
    switch (history.location.pathname) {
      case "/":
        navItems.has("a[title=首頁]").addClass('active')
        break
      case "/contact":
        navItems.has("a[title=聯絡我們]").addClass('active')
        break
      default:
        navItems.has("a[title=首頁]").addClass('active')
    }
  }, [history.location.pathname])

  return (
    <div className="navbar">
      <div className="wrapper">
        <ul>
          <li onClick={handleClick}>
            <Link to="/"
              title="首頁"
            >
              首頁
              </Link>
          </li>

          <li onClick={handleClick}>
            <a href="/" title="服務範圍">
              服務範圍
              </a>
          </li>
          <li onClick={handleClick}>
            <Link
              to="/contact"
              title="聯絡我們"
            >
              聯絡我們
              </Link>
          </li>
          <div className="animation"></div>
        </ul>
      </div>
    </div>
  )
};